import React, { Component } from 'react';
//import { HashRouter, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import './scss/style.scss';
import { ProgressSpinner } from 'primereact/progressspinner';

import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import Login from './views/pages/login/Login'

const loading = (
  <div style={{display:'flex', width:'100%', minHeight:'100vh', alignItems:'center', justifyContent:'center'}}>
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
          <span style={{fontSize:'30px'}}>Please wait..</span>
      </div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword/ForgotPassword'));


class App extends Component {

  render() {
    return (
      <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props}/>} />
              {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
              {/* <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} /> */}
              {/* <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} /> */}
              <Route path="/info-system" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </Router>
    );
  }
}

export default App;
