import React, {useState, useEffect, useRef} from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CInvalidFeedback,
  CAlert
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {useSelector, useDispatch} from "react-redux"
import { updateRefresh, updateAccess, updateUsername, updatePermissions} from "../../../redux/user"
import {useHistory} from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';

import { Toast } from 'primereact/toast';

const Login = () => {
  const domain = useSelector((state) => state.user.domain)
  const dispatch = useDispatch()
  
  let history = useHistory()
  const [state, setState] =useState({
      userData:{
          username:'',
          password:''
      },
      errors:{
        username:false,
        password:false
      },
      submit:false,
      data:{},
      invalid_crudentials:false,
      open:false,
      check_role:false,
      not_authorized:false,
      width:window.innerWidth,
      changePassword:false,
      passwordReset:{
        new_password:'',
        confirm_password:''
      },
      passwordResetErr:{
        new_password:false,
        confirm_password:false,
        noMatch:false,
        less:false
      },
      updatePassword:false,
      savedPermissions:{}
  })

  const ref = useRef(state);

  useEffect(() => {
    ref.current = state;
  }, [state]);

  //############### toast setup #####################
    const myToast = useRef(null);

    const showToast = (severityValue, summaryValue, detailValue) => {
        console.log('called toast')
    myToast.current.show({severity: severityValue, summary: summaryValue, detail: detailValue});
    }   

    //#################################################

  function handleChange(event){
    const newUserData = {...state.userData, [event.target.name]:event.target.value}
    const newErrors = {...state.errors, [event.target.name]:false}
    setState({...state, userData:newUserData, errors:newErrors, invalid_crudentials:false, not_authorized:false})
  }

  function handleChangePasswords(event){
    const newPassData = {...state.passwordReset, [event.target.name]:event.target.value}
    let newPassErrors
    if(event.target.name === 'new_password'){
        newPassErrors = {...state.passwordResetErr, [event.target.name]:false, noMatch:false, less:false}
    }else{
      newPassErrors = {...state.passwordResetErr, [event.target.name]:false, noMatch:false}
    }
    
    setState({...state, passwordReset:newPassData, passwordResetErr:newPassErrors, })
  }

  function handleSubmit(){
      let errors = {
        username:false,
        password:false
      }
      let noErrors =true
      

      if(!ref.current.userData.username){
          console.log(ref.current.userData.username)
          errors ={...errors, username:true}
          noErrors=false
      }
      if(!ref.current.userData.password){
        errors ={...errors, password:true}
        noErrors=false
      }

      if(!noErrors){
          setState({...state, errors:errors})
      }else{
          setState({...state, submit:true})
      }
  }

  console.log(ref.current.userData, 'nigga data')

  function handleSubmitPassword(){
      let passwordResetErr = {
        new_password:false,
        confirm_password:false,
        noMatch:false,
        less:false
      }

      let noErrors =true

      if(!state.passwordReset.new_password){
          passwordResetErr ={...passwordResetErr, new_password:true}
          noErrors=false
      }else{
          if(state.passwordReset.new_password.length < 6){
              passwordResetErr = {...passwordResetErr, less: true}
              noErrors = false
          }
      }
      if(!state.passwordReset.confirm_password){
        passwordResetErr ={...passwordResetErr, confirm_password:true}
        noErrors=false
      }
      else{
          if(state.passwordReset.new_password !== state.passwordReset.confirm_password){
              passwordResetErr = {...passwordResetErr, noMatch:true}
              noErrors = false
          }
      }

      if(!noErrors){
          setState({...state, passwordResetErr:passwordResetErr})
      }else{
          setState({...state, updatePassword:true})
      }
  }

  useEffect(()=>{
      if(state.submit){
        let submitData = {
          username:ref.current.userData.username.trim(),
          password:ref.current.userData.password
        }
        console.log(submitData)
        let raw = JSON.stringify(submitData);
        let myHeaders = {
             Accept: 'application/json',
             'Content-Type': 'application/json',
         }
         let requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
         };
         fetch(`${domain}/api/token/`, requestOptions)
         .then(response => response.json())
         .then(result => {
             console.log(result)
             setState({...state, data:result})
         })
         .catch(error => {
           setState({...state, submit:false})
           showToast('error','Error','No internet connection')
         });
      }
  },[state.submit])

  
  //################## submit Pass #########################
  useEffect(()=>{
    if(state.updatePassword){
    async function rundata(){
        if(true){
            let do_refresh =false
            let stop =false
            let refreshed_access=''
            let runSubmit =true

            while(!stop){
                if(do_refresh){
                    let refreshData ={
                        refresh:state.data.refresh
                    }
                    let raw = JSON.stringify(refreshData);
                    let myHeaders = {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                     }
                    let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                    };
                    let then_refresh = await fetch(`${domain}/api/token/refresh/`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log(result)
                        if('access' in result){
                            refreshed_access =result.access
                            do_refresh=false
                            stop=true
                            dispatch(updateAccess(result.access))

                        }else{
                            history.push('/')
                            do_refresh=false
                            stop=true
                            runSubmit=false
                        }
                        
                    })
                    .catch(error => {
                    setState({...state, isLoading:false, no_connection:true})
                    stop=true
                    });

                }

                
                if(runSubmit){
                    let submitData ={
                      current_password:state.userData.password,
                      new_password:state.passwordReset.new_password,
                    }
                    let raw = JSON.stringify(submitData);
                    let myHeaders = {
                        Authorization: `Bearer ${refreshed_access?refreshed_access:state.data.access}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                     }
                    let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                    };

                    //###old

                    let response = await fetch(`${domain}/api/auth/update-password/`, requestOptions)
                    .then(response => response.json())
                    .then(result => {                    
                        console.log(result)
                        if('change' in result){
                          setState({...state, updatePassword:false})
                          dispatch(updateAccess(state.data.access))
                          dispatch(updateRefresh(state.data.refresh))
                          dispatch(updateUsername(state.userData.username))
                          dispatch(updatePermissions(state.savedPermissions.permissions))

                          setState({...state, data:{} , updatePassword:false })
                          history.push('/info-system/dashboard')
                          stop =true
                          
                        }
                        else{
                          do_refresh = true
                            
                        }
                    })
                    .catch(error => {
                        setState({...state, updatePassword:false})
                        showToast('error','Internet error',`Check your internet connection`)
                        stop=true
                    });

                }
                
            }
        }else{
            history.push('/')
        }
    }

        rundata()
}
        
  },[state.updatePassword])
  //################## confirm##########################

  function isEmpty(obj) {
    for(let key in obj) {
        if(obj.hasOwnProperty(key)){
        return false;
        }
    }
    return true;
}

useEffect(()=>{
    if(!isEmpty(state.data)){
        if('access' in state.data){
            setState({...state, check_role:true })
            //history.push('/')
        }else{
            setState({...state, submit:false, invalid_crudentials:true})
        }
    }
}, [state.data])

function gotToForgot(){
  history.push('/forgot-password')
}

// #################################################################




//##################################
//####################### - register event  - ###############################

useEffect(() => {
  const listener = event => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      console.log("Enter key was pressed. Run your function.");
      // callMyFunction();
      // event.preventDefault();
      handleSubmit()
      
    }
  };
  document.addEventListener("keydown", listener);
  return () => {
    document.removeEventListener("keydown", listener);
  };
}, []);

//##################################
//####################### - register event  - ###############################

// function handleKeyUp(event) {
//   if (event.code === "Enter") {
//     handleSubmit()
//   }
// }

// useEffect(() => {
//   window.addEventListener("keyup", handleKeyUp);
//   return () => {
//     window.removeEventListener("keyup", handleKeyUp);
//   };
// }, []);

useEffect(()=>{
  if(state.check_role){
    let myHeaders = {}
        var requestOptions = { 
            method: 'GET',headers: myHeaders,
            redirect: 'follow'
        };
      fetch(`${domain}/api/auth/check-role/${state.userData.username.trim()}/`, requestOptions)
      .then(response => response.json())
      .then(result => {
          // console.log(result.password_change_needed)
          if(result.password_change_needed){
            dispatch(updateAccess(state.data.access))
            dispatch(updateRefresh(state.data.refresh))
            dispatch(updateUsername(state.userData.username))
            dispatch(updatePermissions(result.permissions))

            setState({...state, data:{} ,submit:false, check_role:false})
            history.push('/info-system')
          }else{
            setState({...state, submit:false, check_role:false, changePassword:true, savedPermissions:result})
          }
        
      })
      .catch(error => {
        console.log(error)
        setState({...state, submit:false, check_role:false})
        showToast('error','Error','No internet connection')
      });
  }

}, [state.check_role])


  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <Toast ref={myToast} />
      
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              {state.changePassword?
                <CCard className="p-4">
                <CCardBody>
                <div style={{display:'flex', width:'100%', flexDirection:'column'}}>
                  
                  <CForm>
                    <h1>Password reset</h1>
                    <p className="text-muted">Password change required inorder to preceed.</p>
                    
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder="New password" autoComplete="new-password" name='new_password' onChange={handleChangePasswords}
                      invalid={state.passwordResetErr.new_password || state.passwordResetErr.less}
                      />
                      {state.passwordResetErr.new_password?
                        <CInvalidFeedback className="help-block">
                            Field is required
                        </CInvalidFeedback>
                        :
                        null
                    }
                     {state.passwordResetErr.less?
                        <CInvalidFeedback className="help-block">
                            Password should be atleast 6 characters
                        </CInvalidFeedback>
                        :
                        null
                    }
                    </CInputGroup>
                    
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder="Confirm password" autoComplete="confirm_password" name='confirm_password' onChange={handleChangePasswords}
                      invalid={state.passwordResetErr.confirm_password}
                      />
                      {state.passwordResetErr.confirm_password?
                        <CInvalidFeedback className="help-block">
                            Field is required
                        </CInvalidFeedback>
                        :
                        null
                    }
                    </CInputGroup>
                    {!state.passwordResetErr.noMatch?
                      null
                      :
                      <CAlert color="danger">
                        Passwords don't match
                      </CAlert>

                    }
                    
                    <CRow>
                      <CCol xs="6">
                        <CButton color="primary" className="px-4" onClick={handleSubmitPassword}>{state.updatePassword?<CircularProgress style={{color:'#fff', fontSize:'5px'}}/>:'Change password'}</CButton>
                      </CCol>
                      
                    </CRow>
                  </CForm>
                  
                </div>
                </CCardBody>
              </CCard>

                :
              <>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>

                      <CInput type="text" placeholder="Username" autoComplete="username" name='username' onChange={handleChange}
                      
                      invalid={state.errors.username}
                      />
                      {state.errors.username?
                        <CInvalidFeedback className="help-block">
                            Field is required
                        </CInvalidFeedback>
                        :
                        null
                      }
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" placeholder="Password" autoComplete="current-password" name='password' onChange={handleChange}
                      invalid={state.errors.password}
                      />
                      {state.errors.password?
                        <CInvalidFeedback className="help-block">
                            Field is required
                        </CInvalidFeedback>
                        :
                        null
                    }
                    </CInputGroup>
                    {!state.invalid_crudentials?
                      null
                      :
                      <CAlert color="danger">
                        Invalid crudentials
                      </CAlert>

                    }
                    
                    <CRow>
                      <CCol xs="6">
                        <CButton color="primary" className="px-4" onClick={handleSubmit}>{state.submit?<CircularProgress style={{color:'#fff', fontSize:'5px'}}/>:'Login'}</CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0" onClick={gotToForgot}>Forgot password?</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>WELCOME</h2>
                    <p>TO</p>
                    <p>TAPTIVU INSTUTIONS INFORMATION TRACKER</p>
                    
                  </div>
                </CCardBody>
              </CCard>
              </>
              }
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      
    </div>
  )
}

export default Login
